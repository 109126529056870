export enum PizzaSize {
  small,
  medium,
  large,
  extralarge,
  calzone,
  slice,
  child,
}

export enum DawType {
  napolitan,
  thin,
  fat,
  withoutgluten,
}

export enum ReactQueryKey {
  allPizzas = 'allPizzas',
}

export enum AppDataActions {
  resetAllFilters = 'resetFilter',
  resetCurrentItem = 'resetCurrentItem',
  resetFilterIngredients = 'resetFilterIngredients',
  resetFilterPizzaBase = 'resetFilterPizzaBase',
  resetFilterPizzaType = 'resetFilterPizzaType',
  resetOrder = 'resetOrder',
  updateCurrentItem = 'updateCurrentItem',
  updateFilter = 'updateFilter',
  updateOrder = 'updateOrder',
}

export enum FilterType {
  base = 'base',
  size = 'size',
  daw = 'daw',
  ingredients = 'ingredients',
}

export enum BaseSauce {
  tomato = 'tomato',
  creme = 'creme',
  chocolat = 'chocolat',
}

export enum PizzaType {
  carnivor = 'carnivor',
  vegetarian = 'vegetarian',
  vegan = 'vegan',
}
