import { FC, createContext, useContext, useReducer } from 'react';
import { IAction, IAppDataCtxt, IAppDataState } from 'misc/interfaces';

import { AppDataActions } from 'misc/enums';

const Context = createContext<IAppDataCtxt | undefined>(undefined);

const appReducer = (state: IAppDataState, action: IAction): IAppDataState => {
  console.log('appReducer💥', action.value);

  switch (action.type) {
    // Filters
    case AppDataActions.resetFilterPizzaBase: {
      return { ...state, filter: { ...state.filter, base: undefined } };
    }
    case AppDataActions.resetFilterPizzaType: {
      return { ...state, filter: { ...state.filter, pizzaType: undefined } };
    }
    case AppDataActions.resetFilterIngredients: {
      return { ...state, filter: { ...state.filter, ingredients: undefined } };
    }
    case AppDataActions.resetAllFilters: {
      return { ...state, filter: undefined };
    }

    case AppDataActions.updateFilter: {
      return { ...state, filter: { ...state.filter, ...action.value?.filter } };
    }

    // Current Pizza
    case AppDataActions.updateCurrentItem: {
      return { ...state, currentItem: action.value?.currentItem };
    }

    // case AppDataActions.updateOrder: {
    //   return { ...state, order: action.value.order };
    // }
    // case AppDataActions.resetOrder: {
    //   return { ...state, order: null };
    // }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const AppDataCtxtProvider: FC = ({ children }) => {
  const initContext: IAppDataState = { filter: null, order: null };
  const [state, dispatch] = useReducer(appReducer, initContext);

  const value: IAppDataCtxt = { state, dispatch };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useAppDataContext = () => {
  const context = useContext(Context);
  if (context === undefined) {
    throw new Error('useAppDataContext must be used within a AppDataCtxtProvider');
  }
  return context;
};
