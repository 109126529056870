import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { AppDataCtxtProvider } from 'misc/appDataCtxt';
import type { AppProps } from 'next/app';
import Head from 'next/head';

const instance = createInstance({
  urlBase: 'https://analytics.behrouze.com',
  siteId: 2,
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST',
  },
});

const theme = {
  config: {
    useSystemColorMode: false,
    initialColorMode: 'light',
    cssVarPrefix: 'miam',
  },
};

const extheme = extendTheme(theme);
const queryClient = new QueryClient();

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider theme={extheme}>
      <QueryClientProvider client={queryClient}>
        <MatomoProvider value={instance}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <AppDataCtxtProvider>
            <Component {...pageProps} />
          </AppDataCtxtProvider>
        </MatomoProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}
